.footer{
    background-color: var(--secondary);
    color:white;
    height:100%;
    min-height:24vh;
    text-align: center;
    width:100%;
    margin:0;
}

.footerCol, .highlightCol{
    padding: 1vw;
}

.footer a{
    color:white;
    text-decoration: none;
}

.chamber-logo-container{
    object-fit: cover;
    align-items:center;
    justify-content: center;
}

.chamber-logo {
    max-width:100%;
    max-height:10vh;
    margin-left:auto;
    margin-right:auto;
}

.circle-button{
    width:20%;
}

.footer-texts p{
    margin-bottom:  0px;
}

.footer-buttons{
    background-color: black;
}

.centre{

    margin-left:auto;
    margin-right:auto;
}