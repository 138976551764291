.container {
    text-decoration: none;
}


.event-block-inner{
    background-color:var(--primary-50);
    color:black;
    width:100%;
    height:100%;
}

.event-block-inner:hover{
    background-color:var(--secondary-50);
}

.event-block-inner:active{
    transform:translate(2px);
    background-color: var(--primary);
    width:calc(100% - 2px) !important;
    height:calc(100% - 2px);
}