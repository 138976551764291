.header{
    background-color: var(--primary-75);
    height:15vh;
    width:100%;
    padding:1vh;
    margin:0;
}

.navbar-collapse {
    background-color: var(--primary-75);
    padding:5px;
    border-radius: 0px 0px 5px 5px;
}

.navbar, .navbar .container, .navbar-brand {
    padding:0;
}

.navbar-nav{
    margin-left:auto;
    margin-right:5vw;
}

.header-logo {
    max-height:13vh;
    width:auto;
}
.nav-link{
    border-radius: 2px;
    text-align: center;
}

.nav-link:hover{
    background-color:var(--primary);
}


@media screen and (max-width: 992px){
    .nav-link{
        margin:5px 0 5px 0;
        width:90%;
        margin-left: auto;
        margin-right: auto;
    }
    .navbar-nav{
        width:100%;
    }
    .dropdown-menu {
        width:90%;
        margin-left: auto;
        margin-right: auto;
    }
    .offcanvas.offcanvas-end{
        background-color:var(--primary-25);
        border-left: var(--primary) 3px solid;
    }

    .offcanvas-header{
        background-color:var(--primary-75);
        height: 15vh;
    }

    .offcanvas .bottom-text{
        text-align:center;
        padding-bottom: 5px;
        text-wrap:wrap;
        overflow-wrap:break-word;
    }

    .bottom-nav .nav-link{
        margin-top: 0px;
        margin-bottom: 0px;
    }
}
.navbar-toggler{
    border:none;
}

.login-button{
    background-color: var(--secondary);
    color:white;
    font-family: 'BCSbold', 'Arial'
}