.container {
    text-decoration: none;
}

.committee-headshot{
    margin-top:20px;
    width: 20vw;
    max-width:30vh;
    object-fit:cover;
    border-radius: 50px;
    height: 30vh;
    /* width:auto; */
    margin-bottom:40px;
    
}

@media only screen and (max-width: 992px){
    .committee-headshot{
        width: 250px;
        background-color: pink;
    }
}
@media only screen and (max-width: 768px){
    .committee-headshot{
        width: 250px;
        background-color: pink;
    }
}



a{
    color:var(--secondary);
}

.role-title{
    display:block;
    height:2em;
}

.committee-member{
    /* clear:both; */
    /* height:auto; */
    height:fit-content;
    overflow:hidden;
}

.name-quals::after{
    width:80%;
    height:1px;
    content:" ";
    background-color: var(--secondary);
    display: block;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:10px;
    align-self:flex-end;
    clear:both;
}

.vacancy-disclaimer{
    margin-top:20px;
    clear:both;
}

.linkedin-logo{
    height:20px;
    opacity:70%;
}

.linkedin-logo:hover{
    opacity:100%;
}