.chair-headshot img{
    max-width: 100%;
    border-radius: 50px;
    max-height: 30vh;
    height:auto;
    width:auto;
    margin-bottom:20px;
}

.accordion, .accordion-button, .accordion-button:focus, .accordion-button:not(.collapsed){
    background-color: var(--primary-75);
    box-shadow: none;
}


.accordion-collapse::after{
    height:10px;
    background-color: var(--primary-75);
    width:100%;
    display:block;
    content: "";
}

.accordion .btn, .join-button{
    background-color: var(--primary-25);
    color:black;
    border-color:var(--primary-75);
}

.accordion .btn:hover, .join-button:hover{
    background-color:var(--primary-50);
    border-color:var(--primary-75);
    color:black;
}

.accordion .btn:focus, .join-button:focus, .accordion .btn:active{
    background-color: white;
    color:black;
}