body {
  margin: 0;
  font-family: 'BCSfont', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "BCSfont";
  src: url("fonts/DINNextLTPro-Light.woff") format('woff');
}


@font-face {
  font-family: "BCSfont";
  src: url("fonts/DINNextLTPro-Regular.woff") format('woff');
  font-weight: bold;
}

@font-face{
  font-family: "BCSbold";
  src: url("fonts/DINNextLTPro-Bold.woff") format("woff");
}

h1, h2, h3, h4, h5 {
  font-family: "BCSbold", "Arial", sans-serif;
}

h1 {
  color:var(--secondary)
}

.content {
  padding:20px 5% 20px 5%;
  margin-left: auto;
  margin-right: auto;
  text-align:left;
  width:95%;
}

@media only screen and (min-width: 768px) {
  .content{
    padding: 20px, 10%, 20px, 10%;
    width: 80%;
  }
}

.main{
  background-color: var(--background);
  min-height:calc(100vh - 15vh - 24vh);
  text-align: center;
  max-width:100%;
  width:auto;
  margin:0;
}

.row-highlight {
  width:100%;
  background-color:var(--primary);
  margin:auto;
  padding:20px 10% 20px 10%;
}

:root {
  --text-colour: #000000;
  --background: #F1F1F1;

  --primary: #BCCF00;
  --primary-25: #EEF3BF;
  --primary-50: #DDE77F;
  --primary-75: #CDDB40;
  
  --secondary: #006941;
  --secondary-25: #BFD9CC;
  --secondary-50: #7FB299;
  --secondary-75: #408C66;
}

b {
  font-family: 'BCSbold', 'Arial', sans-serif;
}